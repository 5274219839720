<template>
<v-sheet v-if="organisation">
	<v-row>
		<v-col>
			<v-card>
				<v-card-title>
					<h3 class="ig-headline">{{organisation.name}}</h3>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="6" class="pa-8">
							<v-text-field :label="mwtr.OrganisationAddress" :value="organisation.address.streetAddress"></v-text-field>
							<v-text-field :label="mwtr.OrganisationCity" :value="organisation.address.city"></v-text-field>
							<v-text-field :label="mwtr.OrganisationState" :value="organisation.address.state"></v-text-field>
							<v-text-field :label="mwtr.OrganisationPostcode" :value="organisation.address.postcode"></v-text-field>
						</v-col>
						<v-col class="pa-8">
							<v-text-field 
								v-model="organisation.owner" 
								:label="mwtr.OrganisationOwner">
							</v-text-field>
							<v-select :items="mwsettings.OrganisationTypes" 
								v-model="organisation.type" 
								:label="mwtr.OrganisationType">
							</v-select>
							<v-switch 
								v-model="organisation.universityHospital" 
								:label="mwtr.UniversityHospital">
							</v-switch>

							<v-text-field 
								v-model="organisation.beds" type="number" 
								:label="mwtr.OrganisationBedNumber">
							</v-text-field>			
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="save" color="primary">{{mwtr.SaveButton}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-col>
	</v-row>	
	<v-row>
		<v-col>
			{{mwtr.DepartmentBlurb}}
			<ul>
				<li v-for="id in organisation.departments" :key="id">
					{{departments[id].name}}
				</li>
			</ul>
		</v-col>
		<v-col>
		</v-col>
	</v-row>
			<Saved :trigger="saved" @saved="s => saved = s"></Saved>
</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import Saved from "@/components/notices/Saved"
	export default {
		name: "OrganisationProfile", 
		props: {
			
		},
		data: () => {
			return {
				saved: false
			}
		},
		components: {
			Saved
		},
		computed: {
			...Vuex.mapState({
				auth: state => state.auth,
				currentOrganisation: state => state.currentOrganisation,
				organisation: state => state.organisation, 
				departments: state => state.departments
			})
		},
		methods: {
			save(){
				var self = this;
				var data = {
					address: self.organisation.address,
					owner: self.organisation.owner,
					universityHospital: self.organisation.universityHospital,
					beds: self.organisation.beds
				}
				self.$store.dispatch( "organisation/update",  data).then( () => {
					self.saved = true;
				})
			}
		},
		created(){
			this.$store.dispatch("departments/fetchAll")			
		}

	}
// </script>"